import { GaspardeskApiUrl } from '../../../constants';
import { ApiClientPostResponse } from '../strapi/types.js';

type GaspardeskApiClientPostResponse = { status_code: number } & { [fieldId: string]: string }; // Errors & {

export class GaspardeskApiClient {
  static async signupCustomer(data: Record<string, unknown>) {
    return postToApi('customers/signup/start/', data);
  }
}

/************************************************************************************************
 * Helpers
 ************************************************************************************************/

async function postToApi(endpoint: string, data: Record<string, unknown>): Promise<ApiClientPostResponse> {
  // const query = qs.stringify({ fields, populate, sort, pagination }, { encodeValuesOnly: true });
  const response = await fetch(`${GaspardeskApiUrl}/${endpoint}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, */*',
    },
  });
  const payload: GaspardeskApiClientPostResponse = await response.json();
  const statusCode = response.status || payload.status_code;
  const success = !(statusCode >= 400);
  // Extract all the errors from the response (if any)
  const errors = {};
  Object.keys(payload).forEach((key) => {
    errors[key] = payload[key];
  });
  return { statusCode, success, errors };
}
