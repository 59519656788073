import { NextSeoProps } from 'next-seo';

import pageRoutes, { PageRoute } from '../../router/pageRoutes';

const pageSeoConfigs: { [key in PageRoute]: { title: string; description: string } } = {
  [pageRoutes.AboutUs]: {
    title: 'About Us',
    description: "Discover our company's history, mission, and values. Find out more about our team and what sets us apart.",
  },
  [pageRoutes.Benefits]: {
    title: 'Benefits',
    description: 'Explore the various benefits and advantages our services offer. Learn how we can help improve your business.',
  },
  [pageRoutes.Blog]: {
    title: 'Blog',
    description: 'Stay updated with our latest articles and insights covering industry trends, tips, and best practices.',
  },
  [pageRoutes.ContactUs]: {
    title: 'Contact Us',
    description: 'Have questions or inquiries? Contact our team today for assistance or to learn more about our services.',
  },
  [pageRoutes.CookiesPolicy]: {
    title: 'Cookies Policy',
    description: "Learn about our website's cookie policy and how we use cookies to enhance user experience.",
  },
  [pageRoutes.Demo]: {
    title: 'Demo Request',
    description: 'Request a product demo to experience our services firsthand. Schedule your demo today.',
  },
  [pageRoutes.FreeTrial]: {
    title: 'Free Trial Signup',
    description: 'Sign up for a free trial and experience our services with no commitment. Get started today.',
  },
  [pageRoutes.Index]: {
    title: 'Gaspar AI',
    description: 'Generative AI employee help desk to auto-resolve issues.',
  },
  [pageRoutes.Integrations]: {
    title: 'Integrations',
    description: "Discover our platform's integrations with other tools and services to enhance functionality.",
  },
  [pageRoutes.Platform]: {
    title: 'Platform',
    description: 'Explore our comprehensive platform offering various solutions for different business needs.',
  },
  [pageRoutes.Pricing]: {
    title: 'Pricing',
    description: 'View our service plans and pricing options tailored to meet different business requirements.',
  },
  [pageRoutes.PrivacyPolicy]: {
    title: 'Privacy Policy',
    description: 'Understand our commitment to user privacy. Read our detailed privacy policy for more information.',
  },
  [pageRoutes.GasparForHR]: {
    title: 'Gaspar for HR',
    description: 'Discover our specialized services designed to meet the needs of HR departments.',
  },
  [pageRoutes.GasparForIT]: {
    title: 'Gaspar for IT',
    description: 'Explore our services catering to IT professionals and discover how we can assist your IT needs.',
  },
  [pageRoutes.GasparForSales]: {
    title: 'Gaspar for Sales',
    description: 'Learn how our solutions can boost sales operations and improve overall sales performance.',
  },
  [pageRoutes.HowItWorks]: {
    title: 'How It Works',
    description: 'Learn how Gaspar AI solutions can boost sales operations and improve overall sales performance.',
  },
  [pageRoutes.TermsAndConditions]: {
    title: 'Terms & Conditions',
    description: 'Read and understand our legal terms and conditions governing the use of our services.',
  },
  [pageRoutes.UseCases]: {
    title: 'Use Cases',
    description: 'Explore real-life use cases demonstrating how our services can address specific business scenarios.',
  },
  [pageRoutes.PortalDocumentation]: {
    title: 'Portal Documentation',
    description:
      'Discover the power of simplicity with Gaspar AI&#39;s Admin Portal.Streamline operations, automate tasks, and elevate your workplace efficiency with ease.',
  },
  [pageRoutes.LandingPageAtlassian]: {
    title: 'Atlassian Integration',
    description: 'Discover how Gaspar AI integrates with Atlassian to enhance workplace productivity and efficiency.',
  },
  [pageRoutes.LandingPageSlack]: {
    title: 'Slack Integration',
    description: 'Explore how Gaspar AI integrates with Slack to streamline workplace communication and collaboration.',
  },
  [pageRoutes.LandingPageTeams]: {
    title: 'Teams Integration',
    description: 'Learn how Gaspar AI integrates with Microsoft Teams to enhance workplace productivity and efficiency.',
  },
  [pageRoutes.LandingPageFreshservice]: {
    title: 'FreshService Integration',
    description: 'Discover how Gaspar AI integrates with FreshService to streamline workplace operations and support.',
  },
  [pageRoutes.LandingPageXenceGuide]: {
    title: 'Xence Integration',
    description: 'Explore how Gaspar AI integrates with Xence to enhance workplace productivity and efficiency.',
  },
  [pageRoutes.LandingPageXenceAI]: {
    title: 'Xence AI Integration',
    description: 'Learn how Gaspar AI integrates with Xence AI to enhance workplace productivity and efficiency.',
  },
};

export const getPageSeoConfig = (pageRoute: PageRoute): NextSeoProps => {
  const { title, description } = pageSeoConfigs[pageRoute];
  const url = pageRoute;
  return { title, description, openGraph: { url, title, description } };
};

export const get404ErrorPageSeoConfig = (): NextSeoProps => {
  const title = 'Page Not Found';
  const description = 'The link you clicked may be broken or the page may have been removed';
  return { title, description };
};

export const get500ErrorPageSeoConfig = (): NextSeoProps => {
  const title = 'Error Page';
  const description = 'An unexpected error occured';
  return { title, description };
};
