import classnames from 'classnames';
import React from 'react';

type FormTextAreaProps = React.InputHTMLAttributes<HTMLTextAreaElement> & {
  asteriskClassName?: string;
  label?: string;
  errorMessage?: string;
};
const FormTextArea: React.FC<FormTextAreaProps> = React.memo((props) => {
  const { asteriskClassName, label, id, errorMessage, ...inputProps } = props;
  return (
    <div>
      <label htmlFor={id} className="mb-2 block font-light">
        {label}
        {inputProps.required === true && <span className={asteriskClassName}>*</span>}
      </label>
      <textarea
        id={id}
        className={classnames('max-h-[200px] p-2 bg-pureWhite/10 text-pureWhite w-full rounded-3xl placeholder-white/60', {
          'border-error border-1': !!errorMessage,
        })}
        {...inputProps}
      />
    </div>
  );
});
FormTextArea.displayName = 'FormTextArea';
export default FormTextArea;
