import { def } from '@gaspardesk/utils';
import classNames from 'classnames';
import classnames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React, { useMemo } from 'react';

import pageRoutes from '../../../router/pageRoutes';
import { CONTACT_FORM_ID } from '../../../widgets/interactive/ContactUsWidget';
import { DarkHeroBackdrop, LightHeroBackdrop } from '../../backdrops/HeroBackdrops';
import { ArrowRightIcon } from '../../icons/SvgIcons';

const MAX_WIDTH_CLASSNAME = 'max-w-[800px]';
const BACKDROP_CLASSNAME = 'h-auto min-h-[100vh]';

export enum HeaderSectionDestinationUrl {
  ContactUs = 'contact-us',
  RequestDemo = 'request-demo',
  FreeTrial = 'free-trial',
}
export enum HeaderSectionType {
  WhitePlain = 'white-plain',
  WhiteClouds = 'white-clouds',
  Black = 'black',
}

type HeaderSectionCoreProps = {
  type?: HeaderSectionType;
  children?: React.ReactNode;
  title: string | React.ReactNode;
  isLightMode?: boolean;
  description?: string | React.ReactNode;
  destinationUrl?: HeaderSectionDestinationUrl;
  destinationButtonLabel?: string;
};

export type HeaderSectionProps = HeaderHeroSectionProps | HeaderPlainSectionProps;

const HeaderSection: React.FC<HeaderSectionProps> = (props) => {
  const isHeroSection = 'imageUrl' in props;
  const { type, children } = props;
  const isLightMode = type === HeaderSectionType.WhitePlain || type === HeaderSectionType.WhiteClouds;
  const content = (
    <section
      className={classnames('text-left max-w-screen-2xl py-20 px-8 mx-auto', {
        'text-pureWhite': !isLightMode,
        'text-black': isLightMode,
      })}
    >
      {isHeroSection && <HeaderHeroSection {...props} isLightMode={isLightMode} />}
      {!isHeroSection && <HeaderPlainSection {...props} isLightMode={isLightMode} />}
      {children}
    </section>
  );
  if (type === HeaderSectionType.Black) {
    return <div className="bg-black">{content}</div>;
  }
  if (type === HeaderSectionType.WhitePlain) {
    return <div className="bg-pureWhite">{content}</div>;
  }
  if (type === HeaderSectionType.WhiteClouds) {
    return <LightHeroBackdrop className={BACKDROP_CLASSNAME}>{content}</LightHeroBackdrop>;
  }
  return (
    <DarkHeroBackdrop className={BACKDROP_CLASSNAME} enableShadow={false}>
      {content}
    </DarkHeroBackdrop>
  );
};
HeaderSection.displayName = 'HeaderSection';
export default HeaderSection;

/************************************************************************************************
 * Helpers
 ************************************************************************************************/
type HeaderPlainSectionProps = HeaderSectionCoreProps & { subTitle?: string | React.ReactNode };

const HeaderPlainSection: React.FC<HeaderPlainSectionProps> = (props) => {
  const { title, subTitle, description, isLightMode, destinationUrl } = props;
  return (
    <div className="flex flex-col gap-6">
      <div className="max-w-[1200px]">
        {def(subTitle) && <p className="font-bold text-purple">{subTitle}</p>}
        <h1
          className={classNames(MAX_WIDTH_CLASSNAME, 'text-h1 mb-6 md:mb-2 md:text-h1title leading-none pb-4 font-medium text-shadow-lg')}
        >
          {title}
        </h1>
        {def(description) && typeof description === 'string' && (
          <p className={classNames(MAX_WIDTH_CLASSNAME, 'text-2xl max-w-[800px]')}>{description}</p>
        )}
        {def(description) && typeof description !== 'string' && <>{description}</>}
      </div>
      {def(destinationUrl) && (
        <div className="flex flex-col gap-4 items-center py-4">
          <ContactUsButton isLightMode={isLightMode} destinationUrl={destinationUrl} />
        </div>
      )}
    </div>
  );
};
HeaderPlainSection.displayName = 'HeaderPlainSection';

type HeaderHeroSectionProps = HeaderSectionCoreProps & { imageUrl?: string };
const HeaderHeroSection: React.FC<HeaderHeroSectionProps> = React.memo((props) => {
  const { title, imageUrl, description, destinationUrl, isLightMode, destinationButtonLabel } = props;
  return (
    <div className="flex flex-col md:flex-row gap-6">
      <div className="w-full md:w-1/2 block">
        <Image className="rounded-xl float-end" width={500} height={500} src={imageUrl} alt="Header Hero" />
      </div>
      <div className="w-full md:w-1/2 flex flex-col gap-4">
        <h1 className="h1title relative md:-left-20 md:text-left leading-none pb-4">{title}</h1>
        {def(description) && <div className="md:text-left text-2xl">{description}</div>}
        {def(destinationUrl) && (
          <div className="flex justify-center md:justify-start w-full mt-10 md:mt-2">
            <ContactUsButton destinationUrl={destinationUrl} label={destinationButtonLabel} isLightMode={isLightMode} />
          </div>
        )}
      </div>
    </div>
  );
});
HeaderHeroSection.displayName = 'HeaderHeroSection';

type ContactUsButtonProps = {
  destinationUrl: HeaderSectionDestinationUrl;
  label?: string;
  isLightMode?: boolean;
};
const ContactUsButton: React.FC<ContactUsButtonProps> = React.memo((props) => {
  const { destinationUrl, label, isLightMode } = props;
  const [isHovering, setIsHovering] = React.useState(false);

  const buttonLabel = useMemo(() => {
    if (label) return label;
    switch (destinationUrl) {
      case HeaderSectionDestinationUrl.FreeTrial:
        return 'Free Trial';
      case HeaderSectionDestinationUrl.RequestDemo:
        return 'Demo now';
      case HeaderSectionDestinationUrl.ContactUs:
      default:
        return 'Contact Us';
    }
  }, [label, destinationUrl]);

  const href = useMemo(() => {
    switch (destinationUrl) {
      case HeaderSectionDestinationUrl.FreeTrial:
        return `${pageRoutes.FreeTrial}#${CONTACT_FORM_ID}`;
      case HeaderSectionDestinationUrl.RequestDemo:
        return `${pageRoutes.Demo}#${CONTACT_FORM_ID}`;
      case HeaderSectionDestinationUrl.ContactUs:
      default:
        return `${pageRoutes.ContactUs}#${CONTACT_FORM_ID}`;
    }
  }, [destinationUrl]);

  const offsetClassNames = useMemo(() => {
    switch (destinationUrl) {
      case HeaderSectionDestinationUrl.ContactUs:
        return { 'top-[-65px]': isHovering, 'top-[35px]': !isHovering };
      case HeaderSectionDestinationUrl.RequestDemo:
        return { 'top-[-65px]': isHovering, 'top-[35px]': !isHovering };
      case HeaderSectionDestinationUrl.FreeTrial:
        return { 'top-[-40px]': isHovering, 'top-[45px]': !isHovering };
      default:
        return {};
    }
  }, [isHovering]);

  return (
    <Link
      href={href}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className={classNames(
        'overflow-hidden',
        'select-none cursor-pointer  border-2 rounded-full',
        'relative inline-flex text-center justify-center items-center text-xl',
        'w-[120px] h-[120px] font-medium',
        {
          'border-pureWhite text-pureWhite bg-transparent': !isLightMode,
          'border-black text-black bg-transparent': isLightMode,
        },
      )}
    >
      <div
        className={classnames(
          'absolute transition-top duration-[300ms] ease-in-out',
          'flex flex-col text-center items-center gap-[50px]',
          offsetClassNames,
        )}
      >
        <span className={classnames('text-[21px] font-normal break-words')}>{buttonLabel.toUpperCase()}</span>
        <span className={classnames('text-[200px] scale-[2.8] rotate-45')}>
          <ArrowRightIcon fill={isLightMode ? 'black' : undefined} />
        </span>
      </div>
    </Link>
  );
});
ContactUsButton.displayName = 'ContactUsButton';
