import React from 'react';

type FormCheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & { label?: string };
const FormCheckbox: React.FC<FormCheckboxProps> = React.memo(({ label, id, ...inputProps }) => {
  return (
    <div className="inline-flex items-center justify-center gap-2">
      <input id={id} type="checkbox" {...inputProps} />
      <label htmlFor={id} className="font-light cursor-pointer select-none">
        {label}
      </label>
    </div>
  );
});
FormCheckbox.displayName = 'FormCheckbox';
export default FormCheckbox;
