import React, { useCallback } from 'react';

import { StrapiApiFormPostAttributes } from '../../lib/integrations/strapi/types.js';
import FormButton from './FormButton';
import FormCheckbox from './FormCheckbox';
import FormInput from './FormInput';
import FormTextArea from './FormTextArea';

export enum ContactUsMode {
  Connect = 'Connect',
  FreeTrial = 'Free Trial',
  DemoRequest = 'Demo Request',
}

type SubmitFormData = {
  name: string;
  company: string;
  description: string;
  email: string;
  domain: string;
  newsletterCheckbox: boolean;
};

type ContactFormProps = {
  asteriskClassName?: string;
  formTitle: string;
  formDescription: string;
  submitButtonColor: 'green' | 'blue';
  submitButtonText: string;
  mode: ContactUsMode;
  errors?: Partial<StrapiApiFormPostAttributes>;
  onSubmit: (data: SubmitFormData) => void;
};

const ContactForm: React.FC<ContactFormProps> = React.memo((props) => {
  const { asteriskClassName, formTitle, formDescription, submitButtonColor, mode, submitButtonText, errors, onSubmit } = props;

  const handleFormSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const data = {
        name: event.target['name-input'].value,
        company: event.target['company-input'].value,
        email: event.target['email-input'].value,
        newsletterCheckbox: event.target['newsletter-checkbox-input'].checked,
        description: event.target['description-input']?.value,
        phoneNumber: event.target['phone-input']?.value,
        jobTitle: event.target['jobtitle-input']?.value,
        domain: event.target['domain-input']?.value,
      };
      onSubmit(data);
    },
    [onSubmit],
  );

  const nameInput = (
    <FormInput
      asteriskClassName={asteriskClassName}
      errorMessage={errors?.full_name}
      required={true}
      type="text"
      id="name-input"
      label="Full Name"
      placeholder="Type here..."
    />
  );
  const companyInput = (
    <FormInput
      asteriskClassName={asteriskClassName}
      errorMessage={errors?.company}
      required={true}
      type="text"
      id="company-input"
      label="Company"
      placeholder="Type here..."
    />
  );
  const emailInput = (
    <FormInput
      asteriskClassName={asteriskClassName}
      errorMessage={errors?.email}
      required={true}
      type="email"
      id="email-input"
      label="Email"
      placeholder="Type here..."
    />
  );
  const descriptionInput = (
    <FormTextArea
      asteriskClassName={asteriskClassName}
      errorMessage={errors?.description}
      required={true}
      id="description-input"
      label="Description"
      placeholder="Type here..."
    />
  );
  const jobTitleInput = (
    <FormInput
      asteriskClassName={asteriskClassName}
      errorMessage={errors?.job_title}
      required={true}
      type="string"
      id="jobtitle-input"
      label="Job Title"
      placeholder="Type here..."
    />
  );
  const phoneInput = (
    <FormInput
      asteriskClassName={asteriskClassName}
      errorMessage={errors?.phone_number || errors?.phone}
      required={true}
      type="number"
      id="phone-input"
      label="Phone"
      placeholder="Type here..."
    />
  );
  const domainInput = (
    <FormInput
      asteriskClassName={asteriskClassName}
      errorMessage={errors?.domain}
      required={true}
      type="string"
      id="domain-input"
      label="Workspace Name"
      tooltip="Enter a username for your company's instance using lowercase letter/numbers"
      placeholder="Type here..."
    />
  );

  return (
    <div className="text-center flex flex-col">
      <h2 className="text-6xl pt-6 pb-3">{formTitle}</h2>
      <p className="pb-4">{formDescription} *By submitting, you agree to our Privacy Policy.</p>
      <form onSubmit={handleFormSubmit} className="grid grid-cols-12 gap-4 w-full max-w-4xl self-center text-left">
        {mode === ContactUsMode.Connect && (
          <>
            <div className="col-span-12">{nameInput}</div>
            <div className="col-span-12 md:col-span-6">{companyInput}</div>
            <div className="col-span-12 md:col-span-6">{emailInput}</div>
            <div className="col-span-12">{descriptionInput}</div>
          </>
        )}
        {mode === ContactUsMode.DemoRequest && (
          <>
            <div className="col-span-12">{nameInput}</div>
            <div className="col-span-12 md:col-span-6">{companyInput}</div>
            <div className="col-span-12 md:col-span-6">{emailInput}</div>
            <div className="col-span-12 md:col-span-6">{jobTitleInput}</div>
            <div className="col-span-12 md:col-span-6">{phoneInput}</div>
          </>
        )}
        {mode === ContactUsMode.FreeTrial && (
          <>
            <div className="col-span-12 md:col-span-6">{nameInput}</div>
            <div className="col-span-12 md:col-span-6">{phoneInput}</div>
            <div className="col-span-12 md:col-span-6">{emailInput}</div>
            <div className="col-span-12 md:col-span-6">{companyInput}</div>
            <div className="col-span-12 md:col-span-6">{jobTitleInput}</div>
            <div className="col-span-12 md:col-span-6">{domainInput}</div>
          </>
        )}
        <div className="col-span-12 text-center">
          <FormCheckbox
            id="newsletter-checkbox-input"
            label="By checking this box, I agree to receive company news and updates. Learn more in the Privacy Policy."
          />
        </div>
        <div className="col-span-12 flex justify-center">
          <FormButton color={submitButtonColor}>{submitButtonText}</FormButton>
        </div>
      </form>
    </div>
  );
});
ContactForm.displayName = 'ContactForm';
export default ContactForm;
