import classnames from 'classnames';
import Image from 'next/image';
import React from 'react';

import { GradientBackdropProps } from './types';

type FormBackdropProps = GradientBackdropProps & { imageUrl: string };
const FormBackdrop: React.FC<FormBackdropProps> = React.memo((props) => {
  const { enableShadow, imageUrl, className, children } = props;
  const style = enableShadow
    ? { boxShadow: `0px 50px 70px -30px rgba(0,0,0,0.75) inset, 0px -70px 50px -30px rgba(0,0,0,0.75) inset` }
    : {};
  return (
    <div className={classnames('relative overflow-hidden', className)}>
      <div className="relative z-10" style={style}>
        {children}
      </div>
      <div className="static z-0 select-none" aria-hidden="true">
        <div className="absolute top-20 right-20 rotate-45 scale-[200%]">
          <Image src={imageUrl} width={500} height={500} alt="Animated Gradient Backdrop" />
        </div>
        <div className="absolute top-20 right-20 rotate-45 scale-[200%]">
          <Image src={imageUrl} width={500} height={500} alt="Animated Gradient Backdrop" />
        </div>
        <div className="absolute top-[-10%] right-[40%] rotate-[-10deg] scale-[200%]">
          <Image src={imageUrl} width={500} height={500} alt="Animated Gradient Backdrop" />
        </div>
        <div className="absolute top-40 left-20 rotate-[220deg] scale-[220%] translate-y-[20%]">
          <Image src={imageUrl} width={500} height={500} alt="Animated Gradient Backdrop" />
        </div>
      </div>
    </div>
  );
});
FormBackdrop.displayName = 'FormBackdrop';
export default FormBackdrop;
