import classNames from 'classnames';
import React from 'react';

const SEGMENT_X_OFFSET = 35;

type SegmentedProps = {
  options: string[];
  buttonsClassName?: string;
  selectedClassNames: { [key: string]: string };
  nonSelectedClassName: string;
  selectedOption: string;
  setSelectedOption: (option: string) => void;
};
const Segmented: React.FC<SegmentedProps> = React.memo((props) => {
  const { options, buttonsClassName, selectedClassNames, nonSelectedClassName, selectedOption, setSelectedOption } = props;
  const totalOffset = (options.length - 2) * SEGMENT_X_OFFSET;
  return (
    <div className="flex flex-row relative" style={{ left: `${totalOffset}px` }}>
      {options.map((option, index) => {
        const isSelected = selectedOption === option;
        const offset = index * SEGMENT_X_OFFSET;
        return (
          <button
            key={`option-${index}`}
            style={{ left: `-${offset}px` }}
            className={classNames(
              'select-none inline-flex items-center justify-center relative rounded-full overflow-hidden font-semibold',
              'border-1 border-pureWhite/10',
              'transition-all duration-500 ease-in-out',
              buttonsClassName,
              {
                'z-10': isSelected,
                'z-0': !isSelected,
                [nonSelectedClassName]: !isSelected,
                [selectedClassNames[option]]: isSelected,
              },
            )}
            aria-controls={`segmented-${index}`}
            tabIndex={0}
            role="tab"
            data-toggle="segmented"
            aria-selected={isSelected ? 'true' : 'false'}
            onClick={() => setSelectedOption(option)}
          >
            {option}
          </button>
        );
      })}
    </div>
  );
});
Segmented.displayName = 'Segmented';
export default Segmented;
